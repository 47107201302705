@import '../assets/fonts/fontsVariables';

.example__text {
  @include base_font;
}
.login__view__holder {
  // min-height: 100%;
  background-color: #85858b;
  background-size: cover;
  background-position: center;
  background-blend-mode: multiply;
  display: flex;
  flex-direction: column;
  background-image: url('../assets/images/hotel_zewn_Z_Camera_7.jpg');
}
.webp .login__view__holder{
  background-image: url('../assets/images/hotel_zewn_Z_Camera_7.webp');
}