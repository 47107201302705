@import '../styles/colors';
td .cell {
    min-width: 25vw;
    // width: 40vw;
    background-color: #f2f2f2;
}
.accordion_details {
  display: flex;
  flex-direction: column;
  // align-items: flex-start;
  button{
      margin: 0;
      padding: 0;
  }
}
.link {
    width:100%;
    text-decoration: none;
    display: flex;
    margin: 2px 0;
}
div .paper {
    width: 100%;
  padding: 0 1em;
  background-color: #f4f4f4;
  text-transform: initial;
}
div > .inquiry_header {
  font-weight: 700;
  color: $modularRed;
  text-align: left;
}
.inquiry_text {
  text-align: left;
}
.button_display {
  text-align: center;
  color: white;
  margin: auto;
}