.container {
  width: 1050px;
  height: 650px;
  margin: auto;
  position: relative;
  // max-height: 80vw * 0.7;
  // max-width: 70vh * 2;
}
button.button {
  // backgroundColor: 'white'
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(110%);
  width: 5vmin;
  svg{
    height: 100%;
  }
  // height: 5vmin;
}
.progress_holder {
  position: absolute;
  width: 20%;
  height: 5%;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border: 3px solid white;
}
.progress_fullfill{
  height: 100%;
  background-color: white;
}

.main__view__holder {
  // min-height: 100%;
  background-color: #85858b;
  background-size: cover;
  background-position: center;
  overflow: auto;
  // background-blend-mode: multiply;
  display: flex;
  flex-direction: column;
  // background-image: url("./src/assets/images/hotel_zewn_Z_Camera_7.jpg")
  background-image: url('../assets/images/background-abstract.jpg');
}
:global(.webp) .main__view__holder {
  background-image: url('../assets/images/background-abstract.jpg');
}