@import '../../assets/fonts/fontsVariables';
@import '../../styles/mediaQueries';

.FirstArticleBase {
  @include strong_font;


  box-sizing: border-box;
  padding: 4vh 7vh 2vh 7vh;
  padding: 4vmin 7vmin 2vmin 7vmin;
  max-width: 100vw;
//   @media (min-width: #{$desktop-width  +1}) {
//     height: 100%;
//   }
}
