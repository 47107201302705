@import '../styles/mediaQueries';
@import '../assets/fonts/fontsVariables';
@import '../styles/colors';

// $modularRed: #b01116;
// $modularRedLight: #cc1a20;
// $modularDark: #323337;
$elemSize: 6vmin;
.smallCointainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  // background-color: #85858b;
  //   font-size: 8vmin;

  p {
    @include strong_font;
    margin: 3em 0 1em;
    text-transform: uppercase;
  }
}
.selectedButton {
  background-color: #999;
}
//   :global(.webp) .main__view__holder {
//     background-image: url('../assets/images/background-abstract.jpg');
//   }
.row {
  button {
    padding: 0;
  }
  // display: flex;
  // flex-wrap: nowrap;
}
.btn {
  border: none;
  position: relative;
  // padding: 14px 28px;
  // font-size: 16px;
  cursor: pointer;
  display: inline-block;
  color: white;
  @include base_font;
  -webkit-transition: background-color 800ms linear;
  -ms-transition: background-color 800ms linear;
  transition: background-color 800ms linear;
}

/* On mouse-over */
.btn:hover {
  background-color: $modularRedLight;
  outline-style: none;
  box-shadow: none;
  border-color: transparent;
  opacity: 0.8;
  -webkit-transition: background-color 100ms linear;
  -ms-transition: background-color 100ms linear;
  transition: background-color 100ms linear;
}

.btn:active {
  background-color: $modularRed;
  outline-style: none;
  box-shadow: none;
  border-color: transparent;
  opacity: 0.4;
}
.btn:focus {
  // background-color: transparent;
  outline-style: none;
  box-shadow: none;
  border-color: transparent;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  outline: none;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}
