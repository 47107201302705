@import '../assets/fonts/fontsVariables';
@import '../styles/mediaQueries';
@import '../styles/colors';

.base__text {
  @include base_font;
}

.main__view__setup {
  background-color: #85858b;
  background-size: cover;
  background-position: center;
  background-blend-mode: multiply;
  display: flex;
  flex-direction: column;
  background-image: url('../assets/images/setup.jpg');
}
:global(.webp) .main__view__setup {
  background-image: url('../assets/images/setup.webp');
}

.FirstArticleBaseLogo {
  //  width: 40vw;
  // flex: 0.7;
  width: 97%;
  max-width: 20vw;
  @media (max-width: #{$desktop-width}) {
    max-width: 30vh;
  }
  @media only screen and (orientation: portrait) and (max-width: #{$desktop-width} ) {
    max-width: 50vw;
  }
  min-width: 150px;
  height: auto;
  // margin-bottom: 10%;
  margin: 3vmax 10vw 0 10vw;
}
.formHolder {
  flex: 1;
  display: flex;
  flex-direction: column;
  // align-items: flex-start;
  justify-content: space-between;
}
.inputHolder {
  align-self: flex-end;
  display: flex;
  justify-content: flex-end;
  label {
    color: white;
    @include strong_font;
    text-transform: uppercase;
    font-size: calc(1.5vh + 2vmin);
    &>div{
      margin-top: 0.7em;
      display: flex;
    }
    .textAreaHolder{

    }

    textarea{
      border: 3px solid white;
      background-color: transparent;
      color: white;
      width: 70vmin;
      max-width: 500px;
      text-align: center;
      height: 7vh;
      font-size: calc(1vh + 1.5vmin);
      display:inline-block;
      vertical-align:middle;
      // line-height: 7vh;
    }
  }
  .inputBlock {
    margin: 0 0 0 2vmin;
    width: 10vmin;
    height: auto;
    background-color: $modularRed;
  }
}

.selectionHolderWrapper {
  display: flex;
  justify-content: space-between;
  margin: 1em 4vw 3em 4vw;
  align-items: flex-end;
  flex-wrap: wrap;
}
.selectionHolder {

}
.selectionHolderTitle {
  color: white;
  @include strong_font;
  text-transform: uppercase;
  font-size: 1.6vmax;
  margin: 0.5em 0;

}
.buttonHolder {
  height: 3vmax;
  min-height: 6vmin;
  display: flex;
  align-items: stretch;
  font-size: 1vmax;
}
@media (max-width: #{$desktop-width}) {
  .buttonHolder {
    height: 5vmax;
  }
}


@mixin btnLike {
  position: relative;
  // padding: 14px 28px;
  // font-size: 16px;
  // cursor: pointer;
  display: flex;
  align-items: center;
  color: white;
  @include strong_font;
  transition: opacity 200ms;
  text-transform: uppercase;
  background-color: gray;
  // padding: 0 0.8em;
  border: 1px solid white;
  vertical-align: middle;
  text-align: center;
  justify-content: center;
  margin: 1px;
}
.btnTitle {
  @include btnLike;
  // padding: 14px 28px;
  // font-size: 16px;
  // cursor: pointer;
  @include strong_font;
  color: white;
  background-color: $modularDark;
  border: 1px solid white;
  width: 11vmax;
}
.btnSelectable {
  @include btnLike;
  font-size: 1.5em;
  cursor: pointer;
  @include strong_font;
  color: white;
  background-color: white;
  color: $modularDark;
  border: 1px solid white;
  width: 14vmax;
}
/* On mouse-over */
.btnSelectable:hover {
  outline-style: none;
  box-shadow: none;
  opacity: 0.8;
}

.btnSelectable:active {
  outline-style: none;
  box-shadow: none;
  opacity: 0.4;
}
.btnSelectable:focus {
  outline-style: none;
  box-shadow: none;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  outline: none;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}
.buttonForward {
  background-color: $modularRed;
  color: white;
  height: 4.5vmax;
  min-height: 9vmin;
  font-size: 2vmax;
  width: 18vmax;
  flex-grow: 0.2;
  margin-top: 2em;
}