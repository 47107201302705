@import '../assets/fonts/fontsVariables';
@import '../styles/mediaQueries';
@import '../styles/colors';
.imageHolder {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 0;
    overflow: auto;
    // min-width: '20px';
    // max-height: 50vh
    margin: 0 0.2em;
    padding: 0 0.6em;
    position: relative;
    p {
      color: black;
      @include strong_font;
      text-align: center;
      font-weight: 100;
      font-size: 2vmin;
      max-height: 12%;
    }
  }
  .highlight{
    background-color: $modularRedLight;
  }

  .imageHolder::-webkit-scrollbar {
    width: 0.5em;
    // height: 1em;
    background-color: #929397;
  }
  .imageHolder::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 2vw 2vw 2vw rgba(175, 175, 175, 0.8);
    // border: 1px solid #323337;
    background-color: #929397;
  }
  
  .imageHolder::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 2vw 2vw 2vw rgba(175, 175, 175, 0.8);
    // background-color: #323337;
  }