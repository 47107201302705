@import '../assets/fonts/fontsVariables';
@import '../styles/mediaQueries';
.example__text {
  @include base_font;
}
.main__view {
  // min-height: 100%;
  background-color: white;
  background-size: auto 100%;
//   background-size: contain;
  background-position: right;
  background-blend-mode: multiply;
  display: flex;
  flex-direction: column;
  z-index: 0;
  position: relative;
  background-image: url('../assets/images/background-abstract.jpg');
  // background-image: url("./src/assets/images/hotel_zewn_Z_Camera_7.jpg")
}

.triangleCover {
    position: absolute;
    z-index: -1;
}
