@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,400;1,700&display=swap');

@font-face {
    font-family: 'Audiowide';
    src: url('Audiowide-Regular.eot');
    src: url('Audiowide-Regular.eot?#iefix') format('embedded-opentype'),
        url('Audiowide-Regular.woff2') format('woff2'),
        url('Audiowide-Regular.woff') format('woff'),
        url('Audiowide-Regular.ttf') format('truetype'),
        url('Audiowide-Regular.svg#Audiowide-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


// font-family: 'Lato', sans-serif;