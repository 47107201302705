@import '../styles/mediaQueries';
@import '../styles/colors';

.header_button_basic {
  text-transform: uppercase;
  text-decoration: none;
}
.header_button_basic.header_button_selected * {
  color: #b01116;
}
