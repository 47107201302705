@import '../assets/fonts/fontsVariables';
@import '../styles/variables';
@import '../styles/mediaQueries';
.main__container {
  width: 100%;
  min-height: calc(var(--vh, 1vh) * 100);
  height: calc(var(--vh, 1vh) * 100);
  // height: 100% - $footerSize;
  // height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  display: flex;
  flex-direction: column;
  &>*{
    flex-grow: 1;
  }
}

@media (min-device-width: #{$phone-width}) {
  .main__container::-webkit-scrollbar {
    width: 1.2vw;
  }
}

  .main__container::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 2vw rgba(0, 0, 0, 0.8);
  }

  .main__container::-webkit-scrollbar-thumb {
    //   -webkit-box-shadow: inset 0 0 16px rgba(0, 0, 0, 1);
    background-color: #323337;
  }
