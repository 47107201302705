@import '../assets/fonts/fontsVariables';
.btn {
  border: none;
  background-color: transparent;
  position: relative;
  // padding: 14px 28px;
  // font-size: 16px;
  cursor: pointer;
  display: inline-block;
  color: white;
  @include base_font;
  transition: opacity 200ms;
}

/* On mouse-over */
.btn:hover {
  outline-style: none;
  box-shadow: none;
  border-color: transparent;
  opacity: 0.8;
}

.btn:active {
  background-color: transparent;
  outline-style: none;
  box-shadow: none;
  border-color: transparent;
  opacity: 0.4;
}
.btn:focus {
  background-color: transparent;
  outline-style: none;
  box-shadow: none;
  border-color: transparent;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  outline: none;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}
