$elemSize: 6vmin;

@mixin backgroundName {
  background-image: url('../assets/images/uni.png');
}
.buttonHolder {
  // width: $elemSize * 1*2;
  // height: $elemSize * 2.5214 * 2 + 1*$elemSize;

  .topElement {
    @include backgroundName;
    background-size: contain;
    background-repeat: repeat;
    width: $elemSize * 2;
    height: $elemSize * 2.5214;
  }
  .middleElement {
    @include backgroundName;
    background-size: 100% 100%;
    width: $elemSize * 2;
    height: $elemSize;
    // transform: scale(1, 1) ;
    -webkit-transform: rotate(90deg) scale(0.5, 2);
    -moz-transform: rotate(90deg) scale(0.5, 2);
    -ms-transform: rotate(90deg) scale(0.5, 2);
    -o-transform: rotate(90deg) scale(0.5, 2);
    transform: rotate(90deg) scale(0.5, 2);
  }
}
.simpleButton {
  @include backgroundName;
  width: $elemSize * 1;
  height: $elemSize * 2.5214;
  background-size: cover;
  background-position: center;
}
