@import '../../styles/mediaQueries';
@import '../../assets/fonts/fontsVariables';
@import '../../styles/colors';

.SecArticleHolder {
  order: 2;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-items: center;

  margin: 0;
  padding: 0 0 0 4vmin;
  @media (max-width: #{$tablet-width}) {
    flex-wrap: wrap-reverse;
    padding: 0 4vmin 0 4vmin;
  }
}
$base-size: 3.1;
.SecArticleTitle {
  @include strong_font;
  // position: fixed;
  // transform: translateY(-50%);
  // top: 50%;
  right: 0;
  color: black;
  font-size: $base-size * 0.9vh;
  display: flex;
  align-items: center;
  text-align: right;
}
.redBox {
  margin: 0.15em 0 0 0.3em;
  //   margin-top: 0.15em;
  width: 1.2em;
  height: 0.75em;
  background-color: $modularRed;
  display: inline-block;
  //   margin-left: 1em;
}
div .redButtonLink {
  text-decoration: none;
}

div .redButton {
  width: 100%;
  display: block;
  background-color: $modularRed;
  margin: 0.5em 0;
  @include strong_font;
  color: white;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
    background-color: $modularDark;
  }

  .smallText{
    font-size: 65%;
    display: block;
  }
}
div .blackButton {
  background-color: #000;
}

.imageHolder {
  min-width: '20px';
  max-height: 50vh;
}
.image {
  width: 100%;
  object-fit: contain;
  min-width: 120px;
  max-height: 50vh;
}
