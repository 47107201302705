@import '../../assets/fonts/fontsVariables';
@import '../../styles/mediaQueries';

.SecArticleBase {
    @include base_font;
    flex: 2 2;

    box-sizing: border-box;
    padding: 7vh;
    padding: 7vmin;
    // @media (max-width: #{$desktop-width  +1}) {
    //     max-width: 300px;
    //     }
}