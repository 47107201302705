@import '../styles/mediaQueries';
@import '../styles/colors';
$base-size: 0.85;
div.IntegerSlider {
  font-size: $base-size * 1.25vh;
  // font-size: $base-size * 1.25vmax;
  margin: 4em 0;
  @media (max-width: #{$desktop-width}) and (orientation: landscape) {
    margin: 2em 0;
  }
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 3.5em;
  > div {
    margin-bottom: 1.5em;
  }
  span {
    font-size: 1.3em;
  }
  .redBox {
    width: $base-size * 5em;
    height: $base-size * 1em;
    background-color: $modularRed;
    display: inline-block;
    margin-right: 1em;
  }
  .slider {
    font-size: $base-size * 1.25vh;
    // font-size: $base-size * 1.25vmax;
    margin-left: 2em;
    -webkit-appearance: none;
    // width: 100%;
    height: 0.2em;
    background: $modularDark;
    outline: none;
    opacity: 0.7;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
  }
  & .slider::-webkit-slider-thumb {
    font-size: $base-size * 1vh;
    // font-size: $base-size * 1.25vmax;
    -webkit-appearance: none;
    appearance: none;
    width: 2em;
    height: 2em;
    background: white;
    cursor: pointer;
    border-radius: 50%;
    border: 0.15em solid $modularDark;
  }
  & .slider::-moz-range-thumb {
    font-size: $base-size * 1vh;
    // font-size: $base-size * 1.25vmax;
    width: 2em;
    height: 2em;
    background: $modularDark;
    cursor: pointer;
    border-radius: 50%;
    border: 0.15em solid $modularDark;
    background: white;
  }
  .floatingNumber {
    margin-left: 2.2em;
    position: relative;
    width: calc(100% - 3.7em);
    height: 10px;
    & span {
      display: inline-block;
      width: auto;
      margin: 0;
      position: absolute;
      left: 0;
      bottom: -1em;
    }
  }
}
