@import '../assets/fonts/fontsVariables';
@import '../styles/mediaQueries';
@import '../styles/colors';
@mixin btnLike {
  @include strong_font;
  position: relative;
  display: flex;
  align-items: center;
  color: white;
  transition: opacity 200ms;
  transition: background-color 200ms;
  text-transform: uppercase;
  border: 1px solid white;
  vertical-align: middle;
  text-align: center;
  justify-content: center;
}
.btnSelectable {
  @include btnLike;
  font-size: 1.2em;
  font-size: 2.2vh;
  @media (max-width: #{$desktop-width}) {
    font-size: calc(0.8vh + 1.4vw);
  }
  cursor: pointer;
  @include strong_font;
  color: white;
  color: $modularDark;
  border: 1px solid white;
  background-color: $modularRed;
}
/* On mouse-over */
.btnSelectable:hover {
  outline-style: none;
  box-shadow: none;
  opacity: 0.8;
}

.btnSelectable:active {
  outline-style: none;
  box-shadow: none;
  opacity: 0.9;
  background-color: $modularDark;
}
.btnSelectable:focus {
  outline-style: none;
  box-shadow: none;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  outline: none;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}
.buttonForward {
  padding: 1em 2em;
  color: white;
  min-height: 9vmin;
  flex-grow: 0.2;
  margin-top: 2em;
  width: 80%;
}
