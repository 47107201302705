@import '../assets/fonts/fontsVariables';
@import '../styles/mediaQueries';
.labelsHolder {
  text-transform: uppercase;
  flex-grow: 1;
  padding-left: 2em;
  h1 {
    font-size: 1.4em;
  }
  @media (max-width: $desktop-width) {
    display: none;
    }
}
