@import '../styles/mediaQueries';
@import '../assets/fonts/fontsVariables';
@import '../styles/colors';
$base-size: 3.1;
.holder {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-wrap: wrap;
    align-items: center;
}
.slidersHolder {
  text-transform: uppercase;
  // width: 16.2em;
  max-width: 100vw;
}
.SecArticleTitle {
  @include strong_font;
  // position: fixed;
  // transform: translateY(-50%);
  // top: 50%;
  text-transform: uppercase;
  right: 0;
  color: black;
  font-size: $base-size * 0.9vh;
  display: flex;
  align-items: center;
  text-align: left;
}
.redBox {
  margin: 0.15em 0 0 0.8em;
  //   margin-top: 0.15em;
  width: 1.2em;
  height: 1.8em;
  background-color: $modularRed;
  display: inline-block;
  //   margin-left: 1em;
}
button.smallButton {
  min-height: 0;
   padding: 0.7em 1em;
   width: 100%;
   margin: 1em 0;
   font-size: 1.0em;
}
button.selectedButton {
  background-color: white;
  color: black;
  border: 2px solid black;
}
button.selectedButton:hover {
  background-color: $modularRedLight;
}

button.selectedButton:active {
  background-color: $modularDark;

}
button.selectedButton:focus {
  // background-color: $modularRed;
}

// display: flex;
// flex-direction: column;
// justify-content: center;