@import '../assets/fonts/fontsVariables';
@import '../styles/variables';
footer.main_footer {
  background-color: #323337;
  border-top: 1px solid black;
  flex-grow: 0;
  height: $footerSize;
  font-size: 1.35vh;
  // height: 10vmin;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  // flex-wrap: wrap;
  @include base_font;
}
footer.main_footer {
  display: flex;
  justify-content: space-between;
  & > * {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    & > a,
    p {
      box-sizing: border-box;
      text-decoration: none;
      color: white;
    }
    & > a:link {
    }

    & > a:visited {
    }
    & > a:hover {
      font-weight: bold;
    }
    & > a:active {
    }
  }
  & > div {
    // width: 40vh;
    // width: 40vmin;
  }

  & > div:first-child {
    margin: 4.5vh 6vw 2vh;
  }
  & > div:last-child {
    margin: 3vh 6vh 1.5vh;
    p {
      text-align: right;
    }
  }
}
.LogoModularFooter {
  max-height: 80%;
  align-self: center;
  height: 10vmin;
}

@media (max-width: 1023px ) and (orientation: portrait) {
  footer.main_footer {
    flex-wrap: wrap;
    font-size: 1.15vh;
    align-items: center;
    justify-content: center;
    & > * {
      justify-content: space-between;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      & > a,
      p {
        text-decoration: none;
        color: white;
        margin: 0 1vw;
        display: inline-block;
        // margin: 0.2rem 0;
      }
      // & > a::before, p::before{
      //     content: "|"
      // }
    }
    & > div {
      width: 100%;
    }
    & > div:first-child {
      margin: 1.5vh 4vw 0;
    }
    & > div:last-child {
      margin: 0 4vw 1.5vh;
      p {
        text-align: right;
      }
    }
  }

}


// .footer {
// __container {
//     background-color: red;
//     }
