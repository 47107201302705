@import '../../styles/mediaQueries';

$base-size: 3.1;
.FirstArticleHolder {
  margin: 0;
  padding: 1vmax 0;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & h1 {
    margin-left: 7vmin;
    font-size: $base-size * 1vh;
    font-size: $base-size * 1vmax;
  }
  & h2 {
    margin-left: 7vmin;
    font-size: $base-size * 0.68vh;
    font-size: $base-size * 0.68vmax;
  }
  & * {
    text-transform: uppercase;
  }
  .FirstArticleHolderSliders {
    font-size: $base-size * 1vh;
    font-size: $base-size * 1vmax;
    // position: fixed;
    // transform: translateY(-50%);
    // top: 50%;
    width: 90%;
    max-width: 300px;
    @media only screen and (orientation: portrait) and (max-width: #{$tablet-width} ) {
      max-width: 600px;
    }
    // & > * {
    //   margin: 3em 0;
    // }
  }
  .FirstArticleTitle {
    position: fixed;
    transform: translateY(-50%);
    top: 50%;
    right: 0;
  }
}
