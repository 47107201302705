@import '../assets/fonts/fontsVariables';

.example__text {
  @include base_font;
}
.main__view__holder {
  // min-height: 100%;
  background-color: #85858b;
  background-size: cover;
  background-position: center;
  background-blend-mode: multiply;
  display: flex;
  flex-direction: column;
  // background-image: url("./src/assets/images/hotel_zewn_Z_Camera_7.jpg")
  background-image: url('../assets/images/nasza-produkcja.jpg');
}
.webp .main__view__holder{
  background-image: url('../assets/images/nasza-produkcja.webp');
}