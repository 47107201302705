$base_font_family: 'Lato', sans-serif;

$strong_font_family: 'Audiowide', sans-serif;

@mixin base_font {
    font-family: $base_font_family;
}

@mixin strong_font {
    font-family: $strong_font_family;
}
