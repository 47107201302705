@import '../../styles/mediaQueries';
.SecArticleLogin {
  align-self: center;
  // @media (max-width: #{$desktop-width}) {
  //   padding-top: 0vh;
  //   padding-top: 0vmin;
  //   align-self: flex-end;
  // }
  display: flex;
  flex-direction: column;
}
.LoginHeader {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 3.5vh;
}
$logoHeight: 9vh;
.LoginHeader > * {
  height: $logoHeight;
  width: auto;
}
.LoginHeader > *:first-child,
.LoginHeader > *:last-child {
  height: $logoHeight * 0.7;
  margin-bottom: $logoHeight * 0.04;
  position: relative;
}
.LoginHeader > *:first-child {
  right: $logoHeight * -0.15;
}
.LoginHeader > *:last-child {
  left: $logoHeight * -0.15;
}
.MainButton {
  margin: 1.0vh;
  font-size: 3vh;
  font-weight: bolder;
}
.SmallButton {
  margin: 1.0vh;
  font-size: 2.2vh;
  // font-weight: bolder;
}