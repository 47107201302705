.logOutHolder {
    padding-right: 2vw;
    border-width: 2px;
    border-bottom-style: solid;
} 
.logOutText {
    text-transform: uppercase;
}
.logOutUserName {
    position: absolute;
    padding-right: 2vw;
    right: 0;
    font-size: 0.45em;

}