@import '../styles/mediaQueries';

.SectionDouble {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow: none;
  color: white;
  flex: 1;
  // height: 100%;
  //   @media (min-width: #{$desktop-width +1}) {
  //       height: 100%;
  // }
}
