// @import '../../styles/colors';
@import '../styles/colors';
@import '../assets/fonts/fontsVariables';

.main_container {
  // height: 100%;
  overflow-y: auto;
}
.personalData {
  font-size: 60%;
  margin-top: 0;
  margin-bottom: 0;
}
.header_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 1vw;
  text-align: left;
  padding: 1vh 2vw 1vh 0;
  line-height: 130%;
  font-size: 85%;
  & p:last-child {
    align-self: flex-start;
    font-weight: bold;
  }
  border-bottom: 4px solid $modularRed;
}

b {
  font-weight: bold;
}

.section {
  margin: 2vh 1vw;
}

.project_image_holder {
  width: 100%;
  & h1 {
    margin: 2vh 0;
    font-weight: bold;
  }
}
.project_image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 90%;
  line-height: 120%;
  & p {
    margin: 2vh 0;
    text-align: center;
  }
}
.tables {
  display: flex;
  flex-direction: row;
  font-size: 60%;
  justify-content: space-between;
  flex-wrap: wrap;
  line-height: 120%;
  h2 {
    font-weight: bold;
    margin: 2.5vh 0;
  }
  &Options {
    max-width: 35vw;
  }
  &Containers {
    max-width: 55vw;
    th:nth-child(2),
    td:nth-child(2){
      font-weight: bold;
    }
    th:nth-child(1),
    td:nth-child(1),
    th:nth-child(3),
    td:nth-child(3)
    {
        text-align: center;
      }
      th:nth-child(4),
      td:nth-child(4),
      th:nth-child(5),
      td:nth-child(5)
      {
        text-align: right;
      }
    }
    th {
      background-color: #eee;
    }
    th,
    td {
      padding: 0.5em 0.9em;
      vertical-align: middle;
    }
    table,
    th,
    td {
      border: 1px solid black;
      border-collapse: collapse;
      .additional_description {
        text-align: right;
    }
      .description_highlight {
        color: red;
        font-weight: bold;
    }
  }
  .safety_info{
    color: red;
    padding-top: 2em;
    font-weight: 900;;
  }
}
.save_link{
  margin: 2em 0;
  font-size: 70%;
}
.footer {
  display: flex;
  &>div{
    flex: 3;
    &>div{
      background-color: $modularRedLight;
      width: 70%;
      height: 5px;
      margin: 0.5em auto;
    }
  }
  p {
    flex: 1;
    text-align: right;
    font-weight: bolder;
    font-size: 110%;
    line-height: 130%;
    color: $modularRedLight;
    @include strong_font;
  }
}
.additional_elements{
  margin-top: 2em;
  font-size: 90%;
  line-height: 130%;
}