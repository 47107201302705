@import '../../assets/fonts/fontsVariables';
@import '../../styles/mediaQueries';
.FirstArticleMain {
//   flex: 3 2;
  padding-left: 10%;
  display: flex;
//   flex-grow: 0;
  padding-left: 10vw;
  flex-direction: column;
  justify-content: space-between;
//   align-items: flex-start;
  margin: 2% 0;
  & button {
    flex: 0;
    margin: 0 15vw 0 0;
    display: flex;
    @media (max-width: #{$phone-width}) {
      align-self: normal;
      margin: 0;
    }
  }
  @media only screen and (orientation: portrait) and (max-width: #{$desktop-width} ) {
    flex-grow: 1;
  }
  $base-size: 3.1;
  $proportion: 0.7;

  & span {
    text-transform: uppercase;
    display: inline-block;
    font-size: $base-size * 1vh;
    font-size: $base-size * 1vmax;
    padding: $base-size * 0.12em $base-size * 0.22em;
    text-align: left;
    min-width: 6em;
    @include strong_font;
    // margin-right: 1em;
    @media (max-width: #{$phone-width}) {
      font-size: 8vw;
      margin: 0;
    }
  }
  & button:last-child {
    margin-top: 1em;
    & > span {
      font-size: $base-size * $proportion * 1vh;
      font-size: $base-size * $proportion * 1vmax;
      padding: $base-size * $proportion * 0.06em $base-size / $proportion *
        0.22em;
      @media (max-width: #{$phone-width}) {
        font-size: 6vw;
        margin: 0;
      }
    }
  }
}
.FirstArticleBaseLogo {
  //  width: 40vw;
  width: 97%;
  max-width: 35vw;
  @media (max-width: #{$desktop-width}) {
    max-width: 45vh;
  }
  @media only screen and (orientation: portrait) and (max-width: #{$desktop-width} ) {
    max-width: 80vw;
  }
  min-width: 250px;
  height: auto;
  margin-bottom: 10%;
}

.FirstArticleButtonHolder {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    flex-shrink: 1;
    justify-content: space-evenly;

}