@import '../assets/fonts/fontsVariables';
@import '../styles/mediaQueries';
.headerContainer {
//   position: fixed;
  // margin: 4vh 4vw;
  margin: 4vmin 0 3vmin;
  font-size: 1.4vmin;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  flex-grow: 0;
  @media (min-width: $desktop-width + 1px) {
    font-size: 0.85vmin;
    margin: 4vmin 0 0;
  }
  & > * {
    font-size: 3em;
    margin: 0 0.4em;
  }
  & * {
    color: white;
  }
  &.darkfont * {
    color: black;
    // font-weight: bold;
  }
  & > * {
    @include strong_font;
  }
  & *.hightlighted {
    color: red;
  }
}
