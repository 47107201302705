.App {
    text-align: center;
  }
  
  @media screen and (min-width: 960px) {
    html {
        margin-left: calc(100vw - 100%);
        margin-right: 0;
    }
  }
  
  
  ::-webkit-scrollbar-track-piece{
      background-color: #FFF;
  }
  
  // ::-webkit-scrollbar-thumb{
  //     background-color: #CBCBCB;
  //     outline: 2px solid #FFF;
  //     outline-offset: -2px;
  //     border: .1px solid #B7B7B7;
  // }
  
  ::-webkit-scrollbar-thumb:hover{
      background-color: #909090;
  }
  
  .logoContainer {
    /* padding: 2em 1em; */
  }
  
  
  .logoContainer>div {
    height: 25vmin;
    height: 25vh;
    background-color: #282c34;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    /* background-size: contain; */
    background-position: 0 30%; 
    background-image: url('../assets/images/background.jpg');
  }
  :global(.webp) .logoContainer>div{
    background-image: url('../assets/images/background.webp');
  }
  
  .logoContainer>div>p{
    color: rgba(255, 255, 255, 0.644);
    font-size: 2em;
    padding: 0.5em 1em;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  body, html {
    font-size: medium;
  }
  
  /* container display */
  
  .mainContainer{ 
    background-color: #eee;
    // min-height: 100vh 
  }
  
  .summaryContainer{
    background-color: white;
    padding: 1em;
    min-height: 100vh 
  }
  .summarySubtitle{
    color: #aaa;
    text-align: right;
  }
  .basicTextHolder{
    word-break: break-all;
    padding: 1em 2em 2em 2em;
  }
  
    
  .schemaContainer {
    padding: 2em 1em;
  }
  
  .schemaContainer>img {
    width: 100%;
    height: auto;
  }
  
  
  div>.MuiTypography-root {
    font-size: 1rem;
  }
  h1.MuiTypography-root  {
    font-size: 1.5rem;
    font-weight: 500;
  }
  h2.MuiTypography-root  {
    /* font-size: 1.5rem; */
    font-weight: 500;
  }
  div>.MuiTypography-root {
    margin: 0.35em 0;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  ul.top-list {
    list-style: none;
    display: flex;
    justify-content: space-evenly;
  }
  
  ul.top-list li a.selected {
    background-color: #bb2727;
  }
  
  ul.top-list li a{
    background-color: gray;
    /* padding: 1.2em 1.6em; */
    border-radius: 1.0em;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  }
  ul.top-list li a:hover {
    background-color: #111111;
  
  }
  
  ul.top-list li a{
    text-align: center;
    text-decoration: none;
    color: white;
    font-weight: bold;
    padding: 1.2em 1.6em;
    display: block;
  }
  
  
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  .editFormControl .editCellInput {
      font-size: 0.85em;
  }
  
  /* pc */
  /* @media (min-width: 1020px) { */
  
    /* div.editFormControl {
      margin: -15px 10px 0 0;
    } */
    
    div.editFormControl {
      margin: 2px 10px -2px 0;
    }
  
    /*  Table style */
    /* .MuiAccordionDetails-root */
  .MuiAccordionDetails-root.container-table-holder {
    flex-direction: column;
    overflow-x: auto;
    overflow-y: hidden;
  }
  
  .container-tablerow>.MuiTableCell-root{
    border: 1px solid gray;
    text-align: left;
  }
  .container-tablerow>.MuiTableCell-head {
    background-color: #bb2727;
    color: white;
  }
  