body,
html,
#root {
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  box-sizing: border-box;
  /* overflow: hidden; */
  -webkit-text-size-adjust: none;
  font-family: 'Lato', sans-serif;
}

#root {
    overflow: hidden;
    height: auto;
  /* display: flex; */
  /* flex-direction: column; */
  /* justify-content: space-between; */
  /* align-content: space-between; */
  /* flex-wrap: wrap; */
}

.App__container {
  text-align: center;
}
.App{
  overflow: auto;
  overflow-y: scroll;
  height: 100%;
  width: 100%;
}


/* @media (min-width: 1024px) {
  body, html {
    height: 100vh;
  } 
} */