@import '../assets/fonts/fontsVariables';
@import '../styles/mediaQueries';
@import '../styles/colors';
.bottomCacher {
  order: 3;
  align-items: flex-start;
  min-height: 15vh;
  width: 100vw;
  max-height: 20vh;
  // background-color: red;
  overflow-y: hidden;
  overflow-x: auto;
  display: flex;
  padding: 1em 0 2em;
  color: white;
  // align-self: flex-end;
  @media (max-width: #{$desktop-width}) {
    min-height: 12vh;
  }
  @media (max-width: #{$phone-width}) {
    order: 1;
  }
}
.bottomCacher::-webkit-scrollbar {
  width: 1em;
  height: 1em;
}
.bottomCacher::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 2vw 2vw 2vw rgba(0, 0, 0, 0.8);
  border: 1px solid #323337;
  background-color: #929397;
}

.bottomCacher::-webkit-scrollbar-thumb {
  //   -webkit-box-shadow: inset 0 0 16px rgba(0, 0, 0, 1);
  background-color: #323337;
}
.labelsContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.label {
  background-color: $modularDark;
  margin-right: 2em;
  align-self: center;
  p {
    @include strong_font;
    text-transform: uppercase;
    // line-height: 120%;
    font-size: 0.9em;
    margin: 0.4em 0.6em;
    user-select: none;
  }
}
.highlighted {
  background-color: $modularRedLight;
}
// .label:hover{
//   background-color: $modularRed;
// }
.label:active {
  background-color: $modularRed;
}

.imageHolder {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  // min-width: '20px';
  // max-height: 50vh
  margin: 0 0.2em;
  padding: 0 0.6em;
  position: relative;
  p {
    color: black;
    @include strong_font;
    text-align: center;
    font-weight: 100;
    font-size: 2vmin;
    max-height: 12%;
  }
  .bottomDate {
    font-size: 0.8em;
    position: absolute;
    bottom: 0;
  }
}
.image {
  height: 88%;
  object-fit: contain;
  // min-width: 120px;
  // max-height: 50vh;
}
.selected {
  background-color: $modularRedLight;
  padding: 0.4em 1.4em;
  position: absolute;
  bottom: -1.7em;

  span {
    color: white;
    @include strong_font;
    font-size: 0.7em;
  }
}

.popupOverlay {
  min-width: 10vw;
  padding: 1em;
  p {
    text-align: center;
  }
  p:first-letter {
    text-transform: capitalize;
  }
  &>div>div{
    max-height: 50vh;
  }
}
