@import '../../styles/mediaQueries';
.FirstArticlelogin {
  padding-top: 10vh;
  padding-top: 10vmin;
  flex: 3 3;
  & * {
    text-transform: uppercase;
  }
  & h1 {
    font-size: 9.5vh;
    font-size: 9.5vmin;
    margin-bottom: 1vh;
  }
  & h2 {
    font-size: 4.5vh;
    font-size: 4.5vmin;
    margin-bottom: 1vh;
  }
  & h3 {
    font-size: 2.8vh;
    font-size: 2.8vmin;
  }
  
}
